import React from "react";

import User from "../../model/user";
import Order from "../../model/order";
import utils from "../../utils";

import DataTable from "../global/DataTable";
import Button from "../global/Button";
import IconButton from "../global/IconButton";

import generic from "../../model/generic";
import API from "../../api";

export default class extends React.Component {

    componentDidMount() {
        generic.addEntityListener("order", this.refresh);
    }

    onCreateOrder = () => {
        window.openOrderForm({on_send: this.onSendOrderEmail});
    };

    onEditOrder = (order_id) => {
        window.openOrderForm({action: 'edit', order_id: order_id, on_send: this.onSendOrderEmail});
    };

    onDeleteOrder = (order_id) => {
        window.openConfirmActionModal({
            action_text: "Voulez-vous vraiment supprimer ce bon de commande ?",
            confirm_cb: () => {
                generic.deleteEntity("order", order_id);
            }
        });
    };

    onShowOrder = (order_id) => {
        window.openOrderDisplayModal({order_id: order_id});
    };

    onDownloadOrder = (order_id) => {
        window.openOrderDisplayModal({order_id: order_id, download: true});
    };

    onSendOrderEmail = (order_id) => {
        window.openOrderEmailModal({order_id: order_id});
    }

    onMarkOrderAsDelivered = (order_id) => {
        window.openConfirmActionModal({
            action_text: "Voulez-vous vraiment marquer ce bon de commande comme livré ?",
            confirm_cb: () => {
                Order.markOrderAsDelivered(order_id, this.refresh, (err) => {
                    window.alertPopup("Erreur", "Erreur lors de la mise à jour du bon de commande.");
                });
            }
        });
    }

    getOrderActionButtons = (order_id) => {
        let order = generic.getEntity("order", order_id),
            supplier = generic.getEntity("supplier", order.supplier),
            email_already_sent = order.email_sent_date != null,
            delivered = order.delivery_date != null;
        let email_already_sent_text = email_already_sent ? `Bon de commande envoyé par e-mail le ${order.email_sent_date}` : "Email non envoyé";
        let delivered_text = delivered ? `Bon de commande livré le ${order.delivery_date}` : "Non livré";
        let actions = [
            <IconButton key={order_id + "-mail"} icon="mail" color="green" disabled={!supplier.email || email_already_sent}
                        tooltip={!supplier.email ? "Le fournisseur n'a pas d'addresse e-mail associée" : (email_already_sent ? email_already_sent_text : "Envoyer par mail")}
                        onClick={this.onSendOrderEmail.bind(null, order_id)} className="lm" />,
            <IconButton key={order_id + "-show"} icon="visibility" color="green"
                        tooltip={"Afficher le bon de commande"}
                        onClick={this.onShowOrder.bind(null, order_id)} className="lm" />,
            <IconButton key={order_id + "-download"} icon="download" color="blue"
                        tooltip={"Télécharger le bon de commande"}
                        onClick={this.onDownloadOrder.bind(null, order_id)} className="lm" />,
            <IconButton key={order_id + "-edit"} icon="edit" color="blue" disabled={email_already_sent}
                        tooltip={email_already_sent ? email_already_sent_text : "Modifier le bon de commande"}
                        onClick={this.onEditOrder.bind(null, order_id)} className="lm" />,
            <IconButton key={order_id + "-delete"} icon="delete" color="red" disabled={email_already_sent}
                        tooltip={email_already_sent ? email_already_sent_text : "Supprimer le bon de commande"}
                        onClick={this.onDeleteOrder.bind(null, order_id)} className="lm" />,
            <IconButton key={order_id + "-delivered"} icon="check_circle" color="green" disabled={delivered}
                        tooltip={delivered ? delivered_text : "Marquer comme livré"}
                        onClick={this.onMarkOrderAsDelivered.bind(null, order_id)} className="lm" />
        ];

        return actions;
    };

    getOrderActionButtonsOld = (order_id) => {
        let order = generic.getEntity("order", order_id), supplier = generic.getEntity("supplier", order.supplier);
        let send_email_btn = supplier.email ?
            <i className="material-icons green-text clickable" onClick={this.onSendOrderEmail.bind(null, order_id)}>mail</i> :
            <i className="material-icons grey-text">mail</i>;
        let actions = [
            send_email_btn,
            <i className="material-icons green-text clickable lm" onClick={this.onShowOrder.bind(null, order_id)}>visibility</i>,
            <i className="material-icons blue-text clickable lm" onClick={this.onDownloadOrder.bind(null, order_id)}>download</i>,
            <i className="material-icons blue-text clickable lm" onClick={this.onEditOrder.bind(null, order_id)}>edit</i>,
            <i className="material-icons red-text clickable lm" onClick={this.onDeleteOrder.bind(null, order_id)}>delete</i>
        ];
        return actions;
    };

    refresh = () => {
        this.forceUpdate();
    };

    render(){
        let orders = generic.getEntities("order", true).reverse(), order_rows = [],
            order_headers = ['Date de création', 'Fournisseur', 'N°', 'Actions', 'Email envoyé le', 'Date de livraison'];
        for(let order of orders){
            let supplier = generic.getEntity("supplier", order.supplier);
            order_rows.push({data: [order.date, supplier.name, order.order_number, this.getOrderActionButtons(order.id), order.email_sent_date, order.delivery_date]});
        }
        return <div id="user-order-view" >
            <div className="center-align">
                <Button className='theme-btn' text="Créer bon de commande" onClick={this.onCreateOrder} />
            </div>
            <div>
                <DataTable headers={{headers: order_headers}} rows={order_rows} />
            </div>
        </div>
    }

}
