import API from "../api";
import generic from "./generic";

const self = {

    updateProductStock: (productId, delta, comment, successcb, errorcb) => {
        if (!Number.isInteger(delta) || delta === 0) {
            if (errorcb) {
                errorcb({ message: "Delta must be a non-zero integer", code: 400 });
            }
            return;
        }
        API.updateProductStock(productId, delta, comment, () => {
            generic.loadOwnedEntities("stock_update");
            generic.refreshEntity("product", productId, successcb);
        }, errorcb);
    },

}

export default self;