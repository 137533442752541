import React from "react";
import AgGrid from "../global/AgGrid";
import API from "../../api";
import generic from "../../model/generic";

export default class StockUpdateView extends React.Component {

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state && this.state.gridApi) {
            this.state.gridColumnApi.autoSizeAllColumns();
            // setTimeout(this.state.gridApi.sizeColumnsToFit, 200);
        }
    }

    onGridReady = (event) => {
        this.setState({ gridApi: event.api, gridColumnApi: event.columnApi });
    };

    getHeaders = () => {
        return [
            { headerName: "Date", field: "date" },
            { headerName: "Code Produit", field: "product_ref" },
            { headerName: "Libellé", field: "product_name", filter: 'agTextColumnFilter', floatingFilter: true },
            { headerName: "Quantité", field: "quantity", cellStyle: params => ({ color: params.value > 0 ? "green" : "red" }) },
            { headerName: "Commentaire", field: "comment" }
        ];
    };

    getRows = () => {
        const stock_updates = generic.getEntities("stock_update", true);
        return stock_updates.map((update) => {
            const product = generic.getEntity("product", update.product_id);
            return {
                id: update.id,
                date: update.date,
                product_ref: product ? product.ref : "Unknown",
                product_name: product ? product.name : "Unknown",
                quantity: update.delta,
                comment: update.comment
            };
        });
    };



    render() {
        return (
            <div>
                <h4>Stock Update View</h4>
                <div style={{ height: "500px", textAlign: 'left' }}>
                    <AgGrid
                        columnDefs={this.getHeaders()}
                        rowData={this.getRows()}
                        immutableData={true}
                        getRowNodeId={row => row.id}
                        gridOptions={{
                            onGridReady: this.onGridReady
                        }}
                    />
                </div>
            </div>
        );
    }
}
