import api from "../api";
import generic from "./generic";

const Order = {

    sendOrderEmail: (order_id, data, successcb, errorcb) => {
        api.sendOrderEmail(order_id, data, successcb, errorcb);
    },

    markOrderAsDelivered: (order_id, successcb, errorcb) => {
        api.markOrderAsDelivered(order_id, () => {
            generic.loadOwnedEntities("stock_update");
            generic.loadOwnedEntities("product");
            generic.refreshEntity("order", order_id, successcb);
        }, errorcb);
    }

}

export default Order;