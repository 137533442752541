import React from "react";
import Modal from "../global/Modal";
import Button from "../global/Button";
import InputField from "../global/InputField";

export default class StockUpdateModal extends React.Component {
  state = {
    openModal: null,
    closeModal: null,
    product: null,
    updateType: null,
    delta: "",
    comment: "",
    error: "",
  };

  componentDidMount() {
    window.openStockUpdateModal = this.openModal;
  }

  getOpen = (open) => {
    this.setState({ openModal: open });
  };

  getClose = (close) => {
    this.setState({ closeModal: close });
  };

  openModal = ({ product, updateType, onConfirm }) => {
    this.setState({ product, updateType, onConfirm }, this.state.openModal);
  };

  closeModal = () => {
    this.setState({ product: null, updateType: null, delta: "", comment: "", error: "" }, this.state.closeModal);
  };

  handleDeltaChange = (value) => {
    this.setState({ delta: value });
  };

  handleCommentChange = (value) => {
    this.setState({ comment: value });
  };

  validateInput = () => {
    const { delta, product, updateType } = this.state;
    const deltaValue = parseInt(delta, 10);

    if (!Number.isInteger(deltaValue) || deltaValue <= 0) {
      this.setState({ error: "Veuillez entrer un nombre positif." });
      return false;
    }

    if (updateType === "decrease" && deltaValue > (product.stock || 0)) {
      this.setState({ error: "La quantité à enlever dépasse le stock actuel." });
      return false;
    }

    this.setState({ error: "" });
    return true;
  };

  handleConfirm = () => {
    if (this.validateInput()) {
      const { product, delta, comment, updateType, onConfirm } = this.state;
      const deltaValue = updateType === "decrease" ? -parseInt(delta, 10) : parseInt(delta, 10);
      onConfirm(product.id, deltaValue, comment);
      this.closeModal();
    }
  };

  render() {
    const { product, updateType, delta, comment, error } = this.state;
    const title = updateType === "increase" ? "Augmenter le Stock" : "Enlever du Stock";
    const deltaValue = parseInt(delta, 10);
    const confirm_disabled = !delta || isNaN(deltaValue) || deltaValue <= 0;

    return (
      <Modal
        id="stock-update-modal"
        getOpen={this.getOpen}
        getClose={this.getClose}
        content={
          <div>
            <h4>{title}</h4>
            <p>Product: {product ? product.name : ""}</p>
            <InputField
              label="Quantité"
              type="number"
              step="1"
              min="1"
              value={delta}
              onChange={this.handleDeltaChange}
            />
            <InputField
              label="Commentaire"
              textarea={true}
              value={comment}
              inputStyle={{ height: "100px" }}
              onChange={this.handleCommentChange}
            />
            {error && <p style={{ color: "red" }}>{error}</p>}
            <div className="modal-footer">
              <Button text="Valider" style={{fontSize: "20px"}} className="theme-btn rm" onClick={this.handleConfirm} disabled={confirm_disabled} />
              <Button text="Annuler" large={true} style={{fontSize: "20px", marginLeft: '20px', borderRadius: "18px"}} color="red" onClick={this.closeModal} />
            </div>
          </div>
        }
      />
    );
  }
}
