import Materialize from "materialize-css";
import React       from "react";

import Collection from "./Collection.js";
import ToolTipped from './ToolTipped.js';
import InfoIcon from './InfoIcon';

/**
 * Props : --> id : id to give to the component
 *         --> type : type of input
 *         --> value : value of input
 *         --> placeholder : placeholder for the input
 *         --> defaultValue : value placed in the input when initiated
 *         --> label : label for the input
 *         --> textarea : set to true if you need multi lines input
 *         --> getSetValue : call getSetValue(this.setValue) when componenet is mounted
 *         --> getReset : call getReset(this.reset) when component is mounted
 *         --> onChange : callback(value) when there is a change in the input
 *         --> validate : if true, show valid/invalid colors depending of input type and user entry
 *         --> validateSuccess : custom valid input message
 *         --> validateError : custom invalid input message
 *         --> invalid : if true, input will have invalid color
 *         --> disabled : if true, input will be disabled
 *         --> autoComplete : [{val:val1,tip:tip1},{val:val2},...]
 *         --> autoCompleteMaxDisplay
 *         --> autoCompleteMaxHeight
 *         --> autoCompleteSearch
 *         --> keepAutoCompleteWhenFullSearch (keep autocomplete displayed until selection even if typed search == autocomplete proposition)
 *         --> activeAtMount
 */
export default class InputField extends React.Component {
    state;

    _inputRef = null;

    constructor(props) {
        super(props);
        this.state = {
            id: (props.id ? props.id : window.generateID()),
            selector: null,
            showPassword: false,
            value: props.defaultValue ? props.defaultValue : "",
            autoComplete: []
        }
    }

    componentDidMount() {
        if(this.props.getReset)
            this.props.getReset(this.reset);
        if(this.props.getSetValue)
            this.props.getSetValue(this.setValue);
        Materialize.updateTextFields();
        //window.addEventListener('click', this.onBodyClick)
        if(this.props.autoFocus) {
            this._inputRef.focus();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.defaultValue !== this.props.defaultValue) {
            this.setState({ value: this.props.defaultValue })
        }
    }

    onBodyClick = (event) => {
        // TODO: untested
        // if we are in autocomplete mode, we need to check that we didn't click in the autocomplete results
        console.log(event.target)
        if(event.target && event.target.id.indexOf(`${this.state.id}-autocomplete-collection`) !== 0) {
            this.setState({ autoComplete: [] })
        }
    }

    onFocusOut = (event) => {
        if(this.props.onFocusOut) {
            this.props.onFocusOut();
        }
    }
    autoCompleteSelect = (value) => {
        if(this.props.autoCompleteSelect)
            this.props.autoCompleteSelect(value);
        this.setValue(value);
    }
    setValue = (value) => {
        this.setState({ value: value, autoComplete: [] }, () => {
            if(this.props.onChange)
                this.props.onChange(this.props.type === "number" ? parseFloat(value) : value);
        });
    }
    onChange = (e) => {
        const value = e.target.value;
        this.setState({ value: value }, () => {
            if(this.props.autoComplete)
                this.autoCompleteCheck();
        });
        if(this.props.onChange) {
            this.props.onChange(this.props.type === "number" ? parseFloat(value) : value);
        }
    }
    autoCompleteCheck = () => {
        const val = this.state.value;
        const l = val.length;
        let autoComplete = [];
        if(l > 0) {
            for(let i in this.props.autoComplete) {
                const data = this.props.autoComplete[i];
                if(data.val === val && !this.props.keepAutoCompleteWhenFullSearch) {
                    autoComplete = [];
                    break;
                }
                if(l <= data.val.length && data.val.substring(0, l).toLowerCase() === val.toLowerCase()
                    || (this.props.autoCompleteSearch && data.val.toLowerCase().indexOf(val.toLowerCase()) > -1))
                    autoComplete.push(data);
                if(this.props.autoCompleteMaxDisplay && autoComplete.length === this.props.autoCompleteMaxDisplay)
                    break;
            }
        }
        this.setState({ autoComplete: autoComplete });
    }
    reset = () => {
        this.setState({ value: this.props.defaultValue || "" });
    }
    showPasswordClick = () => {
        this.setState({ showPassword: !this.state.showPassword });
    }

    render() {
        let className = this.props.className || "";
        className += this.props.textarea ? " materialize-textarea" : "";
        if(this.props.validate)
            className = "validate";
        if(this.props.invalid)
            className = (className || "") + " invalid input-error";
        let type = this.props.type || "text";
        if(type === 'password' && this.state.showPassword) {
            type = "text";
        }
        const value    = this.props.value != null ? this.props.value : this.state.value,
              isActive = this.props.value || this.state.value || this.state.value === 0 || this.props.activeAtMount;
        return (
            <div id={this.state.id + "-wrapper"} className={[className, "input-field"].join(" ")}
                 style={this.props.style}>
                {this.props.type === 'password' ?
                    <i className={`material-icons md-${this.state.showPassword ? "visibility_off" : "visibility"} clickable show-password-icon`}
                       onClick={this.showPasswordClick}/> : null}
                {
                    this.props.textarea
                        ? <textarea id={this.state.id}
                                    placeholder={this.props.placeholder ? this.props.placeholder : ""}
                                    value={value}
                                    onChange={this.onChange}
                                    onBlur={this.onFocusOut}
                                    className={className}
                                    style={this.props.inputStyle}
                                    disabled={this.props.disabled ? 'disabled' : false}/>
                        : <input id={this.state.id}
                                 type={type}
                                 placeholder={this.props.placeholder ? this.props.placeholder : ""}
                                 value={value}
                                 autoComplete={this.props.autoComplete ? "off" : this.props.browserAutoCompleteKey}
                                 onChange={this.onChange}
                                 onBlur={this.onFocusOut}
                                 className={className}
                                 style={this.props.inputStyle}
                                 data-length={this.props.dataLength}
                                 disabled={this.props.disabled ? 'disabled' : false}
                                 step={this.props.step ? this.props.step : undefined}
                                 min={this.props.min ? this.props.min : undefined}
                                 max={this.props.max ? this.props.max : undefined}
                                 autoCapitalize={this.props.autoCapitalize ? this.props.autoCapitalize : undefined}
                                 ref={c => this._inputRef = c}
                        />
                }
                <label htmlFor={this.state.id}
                       className={isActive ? "active" : ""}
                       data-success={this.props.validateSuccess ? this.props.validateSuccess : ""}
                       data-error={this.props.validateError ? this.props.validateError : ""}>
                    {this.props.label ? this.props.label : ""}
                </label>
                {this.props.infoTooltip ? <div style={{display: 'inline-block', position: 'absolute', right: '5px'}}>
                    <InfoIcon infoText={this.props.infoTooltip} />
                </div> : undefined}
                {this.state.autoComplete.length ?
                    <AutoCompleteCollection id={`${this.state.id}-autocomplete-collection`}
                                            content={this.state.autoComplete}
                                            onClick={this.autoCompleteSelect}
                                            width={this.props.autoCompleteWidth}
                                            maxHeight={this.props.autoCompleteMaxHeight}/>
                    : null}
            </div>
        )
    }
};

class AutoCompleteCollection extends React.Component {
    onClick = (data) => {
        this.props.onClick(data);
    };

    render() {
        const content = [];
        for(let c in this.props.content) {
            const val = this.props.content[c].val;
            const valContent = <div style={{ padding: "10px 20px" }}>{val}</div>;
            const tip = this.props.content[c].tip;
            content.push({
                content: (tip ? <ToolTipped content={valContent} tooltip={tip} position="right"/> : valContent),
                data: val
            });
        }
        return (
            <div id={this.props.id} style={{
                display: (content.length > 0 ? "block" : "none"),
                position: "absolute", top: "3.1rem",
                zIndex: 3000,
                width: this.props.width ? this.props.width : undefined
            }} className="auto-complete">
                <div className="auto-complete-wrapper">
                    <div style={{
                        "maxHeight": (Number.isInteger(this.props.maxHeight) ? this.props.maxHeight + "px" : this.props.maxHeight),
                        "overflowY": (this.props.maxHeight ? "auto" : "")
                    }}>
                        <Collection content={content} onClick={this.onClick} style={{ position: "static" }}
                                    itemStyle={{ padding: 0 }}/>
                    </div>
                </div>
            </div>
        )
    }
}
