import React from "react";
import AgGrid from "../global/AgGrid";
import Button from "../global/Button";
import generic from "../../model/generic";
import stock_update from "../../model/stock_update";
import StockUpdateModal from "../modal/StockUpdateModal";

export default class StockView extends React.Component {

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.state && this.state.gridApi){
            this.state.gridColumnApi.autoSizeAllColumns();
            // setTimeout(this.state.gridApi.sizeColumnsToFit, 200);
        }
    }

    onGridReady = (event) => {
        this.setState({ gridApi: event.api, gridColumnApi: event.columnApi });
    };

    openStockUpdateModal = (productId, updateType) => {
        const selectedProduct = generic.getEntity("product", productId);
        window.openStockUpdateModal({
            product: selectedProduct,
            updateType,
            onConfirm: this.handleStockUpdate,
        });
    };

    handleStockUpdate = (productId, delta, comment) => {
        console.log("Updating stock for product", productId, "by", delta, "with comment", comment);
        stock_update.updateProductStock(
            productId,
            delta,
            comment,
            () => {
                this.forceUpdate();
            },
            (error) => {
                window.alertPopup("Erreur", "Erreur lors de la mise à jour du stock.");
            }
        );
    };

    increaseStock = (productId) => {
        this.openStockUpdateModal(productId, "increase");
    };

    decreaseStock = (productId) => {
        this.openStockUpdateModal(productId, "decrease");
    };

    getHeaders = () => {
        return [
            { headerName: "Code produit", field: "ref" },
            { headerName: "Libellé", field: "name", filter: 'agTextColumnFilter', floatingFilter: true },
            { headerName: "Stock", field: "stock" },
            {
                headerName: "Ajouter",
                field: "add",
                cellRendererFramework: (params) => (
                    <Button
                        color="green"
                        text="+"
                        style={{fontSize: "20px", borderRadius: "15px", width: '50px', height: '30px', lineHeight: '30px'}}
                        onClick={() => this.increaseStock(params.data.id)}
                    />
                ),
            },
            {
                headerName: "Enlever",
                field: "remove",
                cellRendererFramework: (params) => (
                    <Button
                        color="red"
                        text="-"
                        style={{fontSize: "20px", borderRadius: "15px", width: '50px', height: '30px', lineHeight: '30px'}}
                        onClick={() => this.decreaseStock(params.data.id)}
                    />
                ),
            },
        ];
    };

    getRows = () => {
        const products = generic.getEntities("product", true);
        return products.map((product) => ({
            id: product.id,
            ref: product.ref,
            name: product.name,
            stock: product.stock || 0,
        }));
    };

    render() {
        return (
            <div id="stock-view">
                <div style={{ height: "500px", textAlign: 'left' }}>
                    <AgGrid
                        columnDefs={this.getHeaders()}
                        rowData={this.getRows()}
                        immutableData={true}
                        getRowNodeId={(row) => row.id}
                        gridOptions={{
                            onGridReady: this.onGridReady,
                        }}
                    />
                </div>
                <StockUpdateModal />
            </div>
        );
    }
}
