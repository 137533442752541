import React from "react";

import DataTable from "../global/DataTable";
import Button from "../global/Button";
import IconButton from "../global/IconButton";

import generic from "../../model/generic";
import API from "../../api";

export default class extends React.Component {

    onCreateOrder = () => {
        window.openOrderForm();
    };

    onSendOrderEmail = (order_id) => {
        window.openOrderEmailModal({order_id: order_id});
    }

    onEditOrder = (order_id) => {
        window.openOrderForm({action: 'edit', order_id: order_id});
    };

    onDeleteOrder = (order_id) => {
        window.openConfirmActionModal({
            action_text: "Voulez-vous vraiment supprimer ce bon de commande ?",
            confirm_cb: () => {
                generic.deleteEntity("order", order_id);
            }
        });
    };

    onShowOrder = (order_id) => {
        window.openOrderDisplayModal({order_id: order_id});
    };

    onDownloadOrder = (order_id) => {
        window.openOrderDisplayModal({order_id: order_id, download: true});
    };

    onMarkOrderAsDelivered = (order_id) => {
        window.openConfirmActionModal({
            action_text: "Voulez-vous vraiment marquer ce bon de commande comme livré ?",
            confirm_cb: () => {
                API.markOrderAsDelivered(order_id, () => {
                    generic.reloadEntity("order", order_id);
                    this.refresh();
                });
            }
        });
    }

    render(){
        let orders = generic.getEntities("order", true).reverse(), order_cards = [];
        for(let order of orders){
            order_cards.push(<OrderCard order={order}
                                        onSendOrderEmail={this.onSendOrderEmail}
                                        onShowOrder={this.onShowOrder}
                                        onDownloadOrder={this.onDownloadOrder}
                                        onEditOrder={this.onEditOrder}
                                        onDeleteOrder={this.onDeleteOrder}
                                        onMarkOrderAsDelivered={this.onMarkOrderAsDelivered} />);
        }
        return <div id="user-mobile-order-view" >
            <div className="center-align" style={{marginTop: '10px', marginBottom: '15px'}}>
                <Button className='theme-btn' text="Créer bon de commande" onClick={this.onCreateOrder} />
            </div>
            <div>
                {order_cards}
            </div>
        </div>
    }

}

class OrderCard extends React.Component {

    getOrderActionButtons = (order_id) => {
        let order = generic.getEntity("order", order_id),
            supplier = generic.getEntity("supplier", order.supplier),
            email_already_sent = order.email_sent_date != null,
            delivered = order.delivery_date != null;
        let email_already_sent_text = email_already_sent ? `Bon de commande envoyé par e-mail le ${order.email_sent_date}` : "Email non envoyé";
        let delivered_text = delivered ? `Bon de commande livré le ${order.delivery_date}` : "Non livré";
        let actions = [
            <IconButton key={order_id + "-mail"} icon="mail" color="green" disabled={!supplier.email || email_already_sent}
                        tooltip={!supplier.email ? "Le fournisseur n'a pas d'addresse e-mail associée" : (email_already_sent ? email_already_sent_text : "Envoyer par mail")}
                        onClick={this.props.onSendOrderEmail.bind(null, order_id)} className="lm" disableTooltip={!(!supplier.email || email_already_sent)} />,
            <IconButton key={order_id + "-show"} icon="visibility" color="green"
                        tooltip={"Afficher le bon de commande"}
                        onClick={this.props.onShowOrder.bind(null, order_id)} className="lm" disableTooltip={true} />,
            <IconButton key={order_id + "-download"} icon="download" color="blue"
                        tooltip={"Télécharger le bon de commande"} disableTooltip={true}
                        onClick={this.props.onDownloadOrder.bind(null, order_id)} className="lm" />,
            <IconButton key={order_id + "-edit"} icon="edit" color="blue" disabled={email_already_sent}
                        tooltip={email_already_sent ? email_already_sent_text : "Modifier le bon de commande"}
                        onClick={this.props.onEditOrder.bind(null, order_id)} className="lm" disableTooltip={!email_already_sent} />,
            <IconButton key={order_id + "-delete"} icon="delete" color="red" disabled={email_already_sent}
                        tooltip={email_already_sent ? email_already_sent_text : "Supprimer le bon de commande"}
                        onClick={this.props.onDeleteOrder.bind(null, order_id)} className="lm" disableTooltip={!email_already_sent} />,
            <IconButton key={order_id + "-delivered"} icon="check_circle" color="green" disabled={delivered}
                        tooltip={delivered ? delivered_text : "Marquer comme livré"}
                        onClick={this.props.onMarkOrderAsDelivered.bind(null, order_id)} className="lm" disableTooltip={!delivered} />
        ];

        return actions;
    };

    render(){
        let order = this.props.order, supplier = generic.getEntity("supplier", order.supplier);
        return <div className="card order-card">
            <div className="card-content">
                <div className="tm-5"><span className="bold">Date : </span><span>{order.date}</span><span className="bold">{" | N° " + order.order_number}</span></div>
                <div className="tm-5"><span className="bold">Fournisseur : </span><span>{supplier.name}</span></div>
                <div className="tm-5">
                    {this.getOrderActionButtons(order.id)}
                </div>
            </div>
        </div>
    }
}
